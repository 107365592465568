.italianPizzaContainer {
  width: 75%;
  height: 90vh;
  position: relative;
  left: 2.5%;
  display: flex;
  flex : {
    direction: column;
  }
  align-items: center;
  justify-content: space-between;
  .pizzasContainer {
    width: 80%;
    height: calc(100% - 10vh);
    position: relative;
    left: 10%;
    display: flex;
    flex : {
      direction: column;
    }
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    opacity: 0;
    transform: scale(0);
    .pizzaContainer {
      width: 100%;
      height: max-content;
      overflow-y: scroll;
      flex : {
        direction: column;
      }
      align-items: center;
    }
    .pizzaModel {
      width: 90%;
      height: 30vh;
      display: flex;
      flex : {
        direction: row;
      }
      align-items: center;
      justify-content: space-between;
      background-color: #ccc;
      margin: 1vw;
      position: relative;
      left: 5%;
      .left {
        width: 40%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        display: flex;
        flex : {
          direction: column;
        }
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 1vw;
        .innerPizza {
          display: flex;
          flex : {
            direction: column;
          }
          align-items: center;
          justify-content: center;
          .innerContent {
            text-align: right;
          }

          .addToCart {
            width: 10vw;
            height: 3vh;
            background-color: green;
            padding: 1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1vw;
          }
        }
      }
    }
  }
}
