.cartItemsContainer {
  width: 90vw;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  left: 5vw;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  font-size: 2vw;
}

.cartItemsContainer .customHr {
  width: 90%;
  height: 0.5vh;
  background: #64f;
  position: relative;
  left: 5%;
}

.cartItemsContainer .beforeFood, .cartItemsContainer .americaPizza, .cartItemsContainer .italianPizza, .cartItemsContainer .drinksItems {
  width: 80%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  left: 10%;
  text-align: center;
  font-size: 1vw;
}

.cartItemsContainer .beforeFoodItem {
  width: 100%;
  height: 25vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  background-color: #cec4ff;
  border-radius: 2vw;
  margin: 1vw;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2vw;
}

.cartItemsContainer .beforeFoodItem:hover {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.cartItemsContainer .beforeFoodItem .left {
  width: 20%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.cartItemsContainer .beforeFoodItem .left img {
  width: 90%;
}

.cartItemsContainer .beforeFoodItem .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cartItemsContainer .beforeFoodItem .right h1 font {
  size: 1.3vw;
}
