.headerHleper {
  width: 80%;
  height: 15vh;
  display: flex;
  flex : {
    direction: row;
  }
  align-items: center;
  justify-content: center;
  position: relative;
  left: 10%;
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex : {
      direction: row-reverse;
    }
    align-items: center;
    justify-content: space-between;
  }
  a {
    display: flex;
    flex: {
      direction: row;
    }
    align-items: center;
    justify-content: center;
    width: 8vw;
    height: 2vh;
    padding: 1.3vw;
    background-color: rgba(74, 54, 163, 0.933);
    color: #fff;
    font : {
      size: 1.5vw;
    }
    border-radius: 1vh;
  }
  .bold {
    font-weight: bold;
  }
}
