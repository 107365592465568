main.MyPizzaMaker {
  width: 90%;
  height: 92vh;
  position: relative;
  left: 2.5%;
  display: flex;
  flex : {
    direction: row;
  }
  align-items: center;
  justify-content: space-between;

  .left {
    width: 20%;
    height: 40%;
    position: relative;
    .topLeft {
      width: 50%;
      height: 50%;
      border : {
        bottom: 1px solid black;
        right: 1px solid black;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2vw;
    }
    .bottomLeft {
      width: 50%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2vw;
    }
    .topRight {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2vw;
    }
    .bottomRight {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      border : {
        top: 1px solid black;
        left: 1px solid black;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2vw;
    }
  }
  .mid {
    width: 60%;
    height: 80%;
    position: relative;

    .top {
      width: 27vw;
      height: 27vw;
      position: relative;
      left: 10vw;
      .contentsOfPizza {
        width: 80%;
        height: 80%;
        .bread {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: {
            image: url("../../Images/breads/1.png");
            size: cover;
            repeat: no-repeat;
          }
          position: relative;
          .sos {
            width: 90%;
            height: 90%;
            border-radius: 50%;
            position: relative;
            z-index: 20;
            top: 5%;
            left: 5%;
            background: {
              size: cover;
              repeat: no-repeat;
            }
          }

          .chease {
            width: 35%;
            height: 35%;
            border-radius: 99%;
            position: absolute;
            top: 20%;
            left: 20%;
            z-index: 20;
            background: {
              size: cover;
              repeat: no-repeat;
            }
          }
          .veg {
            width: 35%;
            height: 35%;
            border-radius: 99%;
            position: absolute;
            top: 50%;
            left: 35%;
            z-index: 20;
            background: {
              size: cover;
              repeat: no-repeat;
            }
          }
          .meat {
            width: 35%;
            height: 35%;
            border-radius: 99%;
            position: absolute;
            top: 20%;
            left: 50%;
            z-index: 20;
            background: {
              size: cover;
              repeat: no-repeat;
            }
          }
        }
      }
    }
    .bottom {
      width: 100%;
      height: 19%;
      display: flex;
      flex : {
        direction: row;
      }
      align-items: center;
      justify-content: space-evenly;
      box-sizing: border-box;
      padding: 2vw;
      transition: all 1s ease;
      .bottomImage {
        transition-delay: 1;
        transition: all 1s ease;
        padding: 0.5vw;
        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }
  .right {
    width: 30%;
    height: 60%;
    display: flex;
    flex : {
      direction: column;
    }
    align-items: center;
    justify-content: space-between;
    button {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: transparent;
      color: #fff;
      font : {
        size: 2.5vw;
      }
      background-color: #f1b709;
      border-radius: 2vw;
      color: #000;
    }
    .addCustomPizzToCart {
      background-color: green;
    }
  }
}
