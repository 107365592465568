main.MyPizzaMaker {
  width: 90%;
  height: 92vh;
  position: relative;
  left: 2.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

main.MyPizzaMaker .left {
  width: 20%;
  height: 40%;
  position: relative;
}

main.MyPizzaMaker .left .topLeft {
  width: 50%;
  height: 50%;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2vw;
}

main.MyPizzaMaker .left .bottomLeft {
  width: 50%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2vw;
}

main.MyPizzaMaker .left .topRight {
  width: 50%;
  height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2vw;
}

main.MyPizzaMaker .left .bottomRight {
  width: 50%;
  height: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 1px solid black;
  border-left: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2vw;
}

main.MyPizzaMaker .mid {
  width: 60%;
  height: 80%;
  position: relative;
}

main.MyPizzaMaker .mid .top {
  width: 27vw;
  height: 27vw;
  position: relative;
  left: 10vw;
}

main.MyPizzaMaker .mid .top .contentsOfPizza {
  width: 80%;
  height: 80%;
}

main.MyPizzaMaker .mid .top .contentsOfPizza .bread {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url("../../Images/breads/1.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

main.MyPizzaMaker .mid .top .contentsOfPizza .bread .sos {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  position: relative;
  z-index: 20;
  top: 5%;
  left: 5%;
  background-size: cover;
  background-repeat: no-repeat;
}

main.MyPizzaMaker .mid .top .contentsOfPizza .bread .chease {
  width: 35%;
  height: 35%;
  border-radius: 99%;
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 20;
  background-size: cover;
  background-repeat: no-repeat;
}

main.MyPizzaMaker .mid .top .contentsOfPizza .bread .veg {
  width: 35%;
  height: 35%;
  border-radius: 99%;
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 20;
  background-size: cover;
  background-repeat: no-repeat;
}

main.MyPizzaMaker .mid .top .contentsOfPizza .bread .meat {
  width: 35%;
  height: 35%;
  border-radius: 99%;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 20;
  background-size: cover;
  background-repeat: no-repeat;
}

main.MyPizzaMaker .mid .bottom {
  width: 100%;
  height: 19%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2vw;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

main.MyPizzaMaker .mid .bottom .bottomImage {
  -webkit-transition-delay: 1;
          transition-delay: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  padding: 0.5vw;
}

main.MyPizzaMaker .mid .bottom .bottomImage:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

main.MyPizzaMaker .right {
  width: 30%;
  height: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

main.MyPizzaMaker .right button {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background-color: transparent;
  color: #fff;
  font-size: 2.5vw;
  background-color: #f1b709;
  border-radius: 2vw;
  color: #000;
}

main.MyPizzaMaker .right .addCustomPizzToCart {
  background-color: green;
}
