@font-face {
  font-family: "farsi";
  src: url("./Fonts/Farsi.ttf");
}
* {
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
  font-family: "farsi";
  text : {
    decoration: none;
  }
  a {
    color: black;
  }
  list-style: none;

}

body {
  background: {
    // color: #fdbf0c;
    image: url("./Images/backgroundOfAll.png");

    // color: darken(#fdbf0c, 5%);
    // color: teal;
    color: #c9a437;
  }
  overflow: hidden;
}
