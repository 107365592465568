@font-face {
  font-family: "farsi";
  src: url("./Fonts/Farsi.ttf");
}

* {
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
  font-family: "farsi";
  text-decoration: none;
  list-style: none;
}

* a {
  color: black;
}

body {
  background-image: url("./Images/backgroundOfAll.png");
  background-color: #c9a437;
  overflow: hidden;
}
