body main.mainRoot {
  width: 100%;
  height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

body main.mainRoot h3 {
  color: #fff;
  font-size: 1.6vw;
}

body main.mainRoot .pizzaContainer {
  width: 30vw;
  height: 30vw;
}

body main.mainRoot .pizzaContainer .leafIcon {
  width: 3vw;
  height: 5vh;
  top: 15%;
  left: 8%;
  z-index: 10;
  position: absolute;
}

body main.mainRoot .pizzaContainer .innerPizza {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

body main.mainRoot .pizzaContainer .innerPizza .bread {
  width: 30vw;
  height: 30vw;
  background-color: #efe3ce;
  border-radius: 50%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages .sus {
  width: 20%;
  height: 20%;
  position: absolute;
  border-radius: 50%;
  background-color: #a03524;
  position: absolute;
  left: 10%;
  top: 50%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages .egg {
  display: block;
  width: 30%;
  height: 30%;
  background-color: #c6786b;
  position: absolute;
  top: 10%;
  left: 20%;
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages .eggTwo {
  display: block;
  width: 30%;
  height: 30%;
  background-color: #e0c0bb;
  position: absolute;
  top: 50%;
  left: 60%;
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages .eggThree {
  display: block;
  width: 30%;
  height: 30%;
  background-color: #e08777;
  position: absolute;
  top: 70%;
  left: 30%;
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .susages .diamond {
  width: 80px;
  height: 80px;
  background: blue;
  margin: 3px 0 0 30px;
  /* Rotate */
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /* Rotate Origin */
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .zeytoonHa {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  top: -100%;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .zeytoonHa .zeytoonContainer {
  width: 10%;
  height: 10%;
  position: absolute;
  background-color: #54504f;
  border-radius: 50%;
  z-index: 3;
  top: 30%;
  left: 15%;
  border: 2px solid black;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .zeytoonHa .zeytoonContainer .zeytoon {
  width: 50%;
  height: 50%;
  background-color: #efe3ce;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  top: calc(25% - 2px);
  left: calc(25% - 2px);
}

body main.mainRoot .pizzaContainer .innerPizza .bread .mushroomsContainer {
  width: 30vw;
  height: 30vw;
  position: relative;
  top: -200%;
  border-radius: 50%;
  z-index: 5;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .mushroomsContainer img {
  width: 5vw;
  height: 5vw;
  position: absolute;
  z-index: 6;
  background-color: #cc9999;
  top: 20%;
  left: 50%;
  padding: 1rem;
  -webkit-clip-path: polygon(10% 25%, 9% 28%, 49% 12%, 93% 27%, 90% 24%, 90% 25%, 90% 50%, 64% 57%, 65% 100%, 35% 100%, 36% 57%, 10% 50%);
          clip-path: polygon(10% 25%, 9% 28%, 49% 12%, 93% 27%, 90% 24%, 90% 25%, 90% 50%, 64% 57%, 65% 100%, 35% 100%, 36% 57%, 10% 50%);
}

body main.mainRoot .pizzaContainer .innerPizza .bread .cheaseContainer {
  width: 30vw;
  height: 30vw;
  position: relative;
  top: -300%;
  border-radius: 50%;
  z-index: 6;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .cheaseContainer .cheasTwo {
  position: absolute;
  width: 2%;
  height: 20%;
  background-color: #ffa600;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .vegContainer {
  width: 30vw;
  height: 30vw;
  position: relative;
  top: -400%;
  border-radius: 50%;
  z-index: 7;
}

body main.mainRoot .pizzaContainer .innerPizza .bread .vegContainer .veg {
  display: inline-block;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  border: 5px solid green;
  width: 3%;
  height: 2%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 7;
  position: absolute;
  background-color: green;
  top: 50%;
  left: 50%;
}

body main.mainRoot #susOne {
  top: 8%;
  left: 20%;
}

body main.mainRoot #susTwo {
  top: 15%;
  left: 55%;
}

body main.mainRoot #susThree {
  top: 45%;
  left: 10%;
}

body main.mainRoot #susFour {
  left: 70%;
  top: 40%;
}

body main.mainRoot #susFive {
  left: 50%;
  top: 70%;
}

body main.mainRoot #susSix {
  left: 20%;
  top: 70%;
}

body main.mainRoot #susSeven {
  left: 40%;
  top: 40%;
}

body main.mainRoot #zeytoonOne {
  left: 50%;
  top: 5%;
}

body main.mainRoot #zeytoonTwo {
  left: 20%;
  top: 33%;
}

body main.mainRoot #zeytoonThree {
  left: 80%;
  top: 65%;
}

body main.mainRoot #zeytoonFour {
  left: 40%;
  top: 63%;
}

body main.mainRoot #zeytoonFive {
  left: 10%;
  top: 65%;
}

body main.mainRoot #mushroomOne {
  left: 25%;
  top: 25%;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

body main.mainRoot #mushroomTwo {
  width: 10%;
  left: 75%;
  top: 15%;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

body main.mainRoot #mushroomThree {
  width: 10%;
  left: 63%;
  top: 53%;
  -webkit-transform: rotate(140deg);
          transform: rotate(140deg);
}

body main.mainRoot #mushroomFour {
  width: 10%;
  left: 5%;
  top: 20%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

body main.mainRoot #mushroomFive {
  width: 10%;
  left: 35%;
  top: 78%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

body main.mainRoot #IDcheaseEleven {
  left: 55%;
  top: 63%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

body main.mainRoot #IDcheaseTwoelve {
  left: 95%;
  top: 53%;
  -webkit-transform: rotate(77deg);
          transform: rotate(77deg);
}

body main.mainRoot #IDcheaseTherteen {
  left: 25%;
  top: 43%;
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}

body main.mainRoot #IDcheaseFourteen {
  left: 15%;
  top: 33%;
  -webkit-transform: rotate(100deg);
          transform: rotate(100deg);
}

body main.mainRoot #IDcheaseFifteen {
  left: 55%;
  top: 23%;
  -webkit-transform: rotate(42deg);
          transform: rotate(42deg);
}

body main.mainRoot #IDcheaseSixteen {
  left: 45%;
  top: 13%;
  -webkit-transform: rotate(42deg);
          transform: rotate(42deg);
}

body main.mainRoot #vegOne {
  width: 1%;
  height: 10%;
  top: 10%;
  left: 48%;
}

body main.mainRoot #vegTwo {
  width: 1%;
  height: 10%;
  top: 33%;
  left: 5%;
}

body main.mainRoot #vegThree {
  width: 1%;
  height: 10%;
  top: 10%;
  left: 48%;
}

body main.mainRoot #vegFour {
  width: 1%;
  height: 8%;
  top: 59.5%;
  left: 55%;
}

body main.mainRoot #leafOne {
  top: 15%;
  left: 10%;
}

body main.mainRoot #leafTwo {
  top: 10%;
  left: 70%;
}

body main.mainRoot #leafThree {
  top: 40%;
  left: 60%;
}

body main.mainRoot #leafFour {
  top: 80%;
  left: 70%;
}

body main.mainRoot #leafFive {
  top: 60%;
  left: 30%;
}
