.itemsContainer {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  button {
    position: relative;
    left: 25%;
  }
  .masterContainer {
    width: 100%;
    height: max-content;
    display: flex;
    flex : {
      direction: column;
    }
    align-items: center;
    justify-content: space-between;

    .cartContainer {
      width: 100%;
      height: 8vw;
      display: flex;
      flex : {
        direction: row;
      }
      align-items: center;
      justify-content: space-between;
      margin: {
        top: 1vh;
        bottom: 1vh;
      }

      .left {
        width: 40%;
        height: 100%;
        display: flex;
        flex : {
          direction: row;
        }
        align-items: center;
        justify-content: center;
      }
      .right {
        width: 80%;
        height: 100%;
        display: flex;
        flex : {
          direction: column;
        }

        text : {
          align: right;
        }
        justify-content: space-evenly;
        .name {
          font : {
            size: 1.5vw;
          }
        }
        .price {
          font : {
            size: 1.3vw;
          }
        }
      }
    }
  }
}
