.navbar {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex : {
    direction: row;
  }
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1vh;
  font-size: 2vw;
  .hamburgerMenuContainer {
    width: 20vw;
    height: 100vh;
    transition: all 1s ease;
    opacity: 0;
    visibility: hidden;
    background-color: teal;
    position: absolute;
    right: -20vw;
    top: 0;
    display: flex;
    flex: {
      direction: column;
    }
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    z-index: 900;
    a {
      width: 90%;
      text : {
        decoration: none;
      }
      color: white;
      font-size: 1.6vw;
      &:first-child {
      }
      display: flex;
      flex : {
        direction: row-reverse;
      }
      align-items: center;
      justify-content: space-between;
    }
    .colse {
      width: 3vw;
      height: 3vw;
      background-color: transparent;
      font-size: 3vw;
      position: absolute;
      z-index: 10054554544545455;
      top: 0.1vw;
      left: 50%;
    }
  }
  .shoppingCartItems {
    width: 20vw;
    height: 100vh;
    transition: all 1s ease;
    opacity: 0;
    visibility: hidden;
    background-color: rgb(165, 76, 165);
    position: absolute;
    right: -20vw;
    top: 0;
    display: flex;
    flex: {
      direction: column;
    }
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .left {
    width: 3%;
    height: 100%;
    display: flex;
    flex : {
      direction: row;
    }
    align-items: center;
    justify-content: center;
  }
  .appDownload {
    width: 10%;
    height: 90%;
    position: relative;
    border: 0.2rem solid #000;
    border-radius: 0.5rem;
    left: 5%;
    display: flex;
    flex: {
      direction: row;
    }
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
  }
  .right {
    width: 10%;
    height: 100%;
    display: flex;
    flex : {
      direction: row;
    }
    align-items: center;
    justify-content: space-between;
    .loginBtn,
    .hamburgerMenu {
      width: 50%;
      height: 80%;
      display: flex;
      flex : {
        direction: row;
      }
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
      }
    }
  }
}
