body {
  main.mainRoot {
    width: 100%;
    height: 90vh;
    display: flex;
    flex : {
      direction: column;
    }
    align-items: center;
    justify-content: space-around;
    h3 {
      color: #fff;
      font : {
        size: 1.6vw;
      }
    }
    .pizzaContainer {
      width: 30vw;
      height: 30vw;
      .leafIcon {
        width: 3vw;
        height: 5vh;
        top: 15%;
        left: 8%;
        z-index: 10;
        position: absolute;
      }
      .innerPizza {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex : {
          direction: row;
        }
        align-items: center;
        justify-content: center;
        .bread {
          width: 30vw;
          height: 30vw;
          background-color: #efe3ce;
          border-radius: 50%;
          .susages {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 50%;
            .sus {
              width: 20%;
              height: 20%;
              position: absolute;
              border-radius: 50%;
              background-color: #a03524;
              position: absolute;
              left: 10%;
              top: 50%;
            }
  
            .egg {
              display: block;
              width: 30%;
              height: 30%;
              background-color: #c6786b;
              position: absolute;
              top: 10%;
              left: 20%;
              -webkit-border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
              border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
            }
            .eggTwo {
              display: block;
              width: 30%;
              height: 30%;
              background-color: #e0c0bb;
              position: absolute;
              top: 50%;
              left: 60%;
              -webkit-border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
              border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
            }
            .eggThree {
              display: block;
              width: 30%;
              height: 30%;
              background-color: #e08777;
              position: absolute;
              top: 70%;
              left: 30%;
              -webkit-border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
              border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
            }
            .diamond {
              width: 80px;
              height: 80px;
              background: blue;
              margin: 3px 0 0 30px;
              /* Rotate */
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              /* Rotate Origin */
              -webkit-transform-origin: 0 100%;
              -moz-transform-origin: 0 100%;
              -ms-transform-origin: 0 100%;
              -o-transform-origin: 0 100%;
              transform-origin: 0 100%;
            }
          }
          .zeytoonHa {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 50%;
            top: -100%;
            .zeytoonContainer {
              width: 10%;
              height: 10%;
              position: absolute;
              background-color: #54504f;
              border-radius: 50%;
              z-index: 3;
              top: 30%;
              left: 15%;
              border: 2px solid black;
              .zeytoon {
                width: 50%;
                height: 50%;
                background-color: #efe3ce;
                border: 2px solid black;
                border-radius: 50%;
                position: absolute;
                top: calc(25% - 2px);
                left: calc(25% - 2px);
              }
            }
          }
          .mushroomsContainer {
            width: 30vw;
            height: 30vw;
            position: relative;
            top: -200%;
  
            border-radius: 50%;
            z-index: 5;
  
            img {
              width: 5vw;
              height: 5vw;
              position: absolute;
              z-index: 6;
              background-color: #cc9999;
              top: 20%;
              left: 50%;
              padding: 1rem;
              clip-path: polygon(
                10% 25%,
                9% 28%,
                49% 12%,
                93% 27%,
                90% 24%,
                90% 25%,
                90% 50%,
                64% 57%,
                65% 100%,
                35% 100%,
                36% 57%,
                10% 50%
              );
            }
          }
          .cheaseContainer {
            width: 30vw;
            height: 30vw;
            position: relative;
            top: -300%;
  
            border-radius: 50%;
            z-index: 6;
  
            .cheasTwo {
              position: absolute;
              width: 2%;
              height: 20%;
              background-color: #ffa600;
            }
          }
          .vegContainer {
            width: 30vw;
            height: 30vw;
            position: relative;
            top: -400%;
            border-radius: 50%;
            z-index: 7;
            .veg {
              display: inline-block;
              transform: rotate(-30deg);
              border: 5px solid green;
              width: 3%;
              height: 2%;
              border-top-left-radius: 50%;
              border-bottom-left-radius: 50%;
              border-bottom-right-radius: 50%;
              z-index: 7;
              position: absolute;
              background-color: green;
              top: 50%;
              left: 50%;
            }
          }
        }
      }
    }
  
    // ID Controlers
    #susOne {
      top: 8%;
      left: 20%;
    }
    #susTwo {
      top: 15%;
      left: 55%;
    }
    #susThree {
      top: 45%;
      left: 10%;
    }
    #susFour {
      left: 70%;
      top: 40%;
    }
    #susFive {
      left: 50%;
      top: 70%;
    }
    #susSix {
      left: 20%;
      top: 70%;
    }
    #susSeven {
      left: 40%;
      top: 40%;
    }
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    #zeytoonOne {
      left: 50%;
      top: 5%;
    }
    #zeytoonTwo {
      left: 20%;
      top: 33%;
    }
    #zeytoonThree {
      left: 80%;
      top: 65%;
    }
    #zeytoonFour {
      left: 40%;
      top: 63%;
    }
    #zeytoonFive {
      left: 10%;
      top: 65%;
    }
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    #mushroomOne {
      left: 25%;
      top: 25%;
      transform: rotate(20deg);
    }
    #mushroomTwo {
      width: 10%;
      left: 75%;
      top: 15%;
      transform: rotate(20deg);
    }
    #mushroomThree {
      width: 10%;
      left: 63%;
      top: 53%;
      transform: rotate(140deg);
    }
    #mushroomFour {
      width: 10%;
      left: 5%;
      top: 20%;
      transform: rotate(0deg);
    }
    #mushroomFive {
      width: 10%;
      left: 35%;
      top: 78%;
      transform: rotate(45deg);
    }
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    #IDcheaseEleven {
      left: 55%;
      top: 63%;
      transform: rotate(90deg);
    }
    #IDcheaseTwoelve {
      left: 95%;
      top: 53%;
      transform: rotate(77deg);
    }
    #IDcheaseTherteen {
      left: 25%;
      top: 43%;
      transform: rotate(120deg);
    }
    #IDcheaseFourteen {
      left: 15%;
      top: 33%;
      transform: rotate(100deg);
    }
    #IDcheaseFifteen {
      left: 55%;
      top: 23%;
      transform: rotate(42deg);
    }
    #IDcheaseSixteen {
      left: 45%;
      top: 13%;
      transform: rotate(42deg);
    }
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    #vegOne {
      width: 1%;
      height: 10%;
      top: 10%;
      left: 48%;
    }
    #vegTwo {
      width: 1%;
      height: 10%;
      top: 33%;
      left: 5%;
    }
    #vegThree {
      width: 1%;
      height: 10%;
      top: 10%;
      left: 48%;
    }
    #vegFour {
      width: 1%;
      height: 8%;
      top: 59.5%;
      left: 55%;
    }
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    #leafOne {
      top: 15%;
      left: 10%;
    }
    #leafTwo {
      top: 10%;
      left: 70%;
    }
    #leafThree {
      top: 40%;
      left: 60%;
    }
    #leafFour {
      top: 80%;
      left: 70%;
    }
    #leafFive {
      top: 60%;
      left: 30%;
    }
    // ID Controlers
  }
}
