.americaPizzaContainer {
  width: 75%;
  height: 90vh;
  position: relative;
  left: 2.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.americaPizzaContainer .americaPizza {
  width: 80%;
  height: calc(100% - 10vh);
  position: relative;
  left: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.americaPizzaContainer .americaPizza .pizzaContainer {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  overflow-y: scroll;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.americaPizzaContainer .americaPizza .pizzaModel {
  width: 90%;
  height: 30vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #ccc;
  margin: 1vw;
  position: relative;
  left: 5%;
}

.americaPizzaContainer .americaPizza .pizzaModel .left {
  width: 40%;
  height: 100%;
}

.americaPizzaContainer .americaPizza .pizzaModel .left img {
  width: 100%;
  height: 100%;
}

.americaPizzaContainer .americaPizza .pizzaModel .right {
  width: 50%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1vw;
}

.americaPizzaContainer .americaPizza .pizzaModel .right .innerPizza {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.americaPizzaContainer .americaPizza .pizzaModel .right .innerPizza .innerContent {
  text-align: right;
}

.americaPizzaContainer .americaPizza .pizzaModel .right .innerPizza .addToCart {
  width: 10vw;
  height: 3vh;
  background-color: green;
  padding: 1vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 1vw;
}
