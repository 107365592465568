.cartItemsContainer {
        width: 90vw;
        height: max-content ;
        position: relative;
        left: 5vw;
        background-color: transparent ;
        display: flex;
        flex : {
            direction: column;
        }
        text : {
            align : center
        }
        font : {
            size : 2vw
        }
        .customHr {
            width: 90%;
            height: 0.5vh;
            background: #64f;
            position: relative;
            left: 5%;
        }
        .beforeFood ,.americaPizza ,.italianPizza ,.drinksItems{
            width: 80%;
            height: max-content;
            display: flex;
            flex: {
                direction: column
            };
            align-items: center;
            justify-content: center;
            position: relative;
            left: 10%;
            text : {
                align : center
            }
            font-size: 1vw;
        }
        .beforeFoodItem  {
            width: 100%;
            height: 25vh;
            display: flex;
            flex : {
                direction: row-reverse;
            }
            align-items:center;
            justify-content: space-between;
            transition: transform 1s ease ;
            background-color: lighten(#64f, 25%);
            border-radius: 2vw;
            margin: 1vw;
            box-sizing: border-box;
            padding: 2vw;
            &:hover {
            flex-direction: row;            
            }
            .left {
                width: 20%;
                height: 100%;
                display: flex;
                flex : {
                    direction: row;
                }
                align-items: center;
                justify-content: center;
                img {
                    width: 90%;
                }
            }
            .right {
                display: flex;
                flex : {
                    direction: column;
                }
                align-items: center;
                justify-content: space-between;
               
                h1 {
                    font {
                        size : 1.3vw
                    }
                }
            }
        }
     
    
}
